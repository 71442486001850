@charset "UTF-8";
/**
 * Variable file
 * place global variables here:
 */
/**
 * Mixin file 
 * All our custom mixins
 *
 * @package buro been
 */
/* Boxsizing shortcut: */
/* Transform shortcuts */
/* Styling placeholders: */
/* Retina images: */
/* "Hard" gradients: */
/* REM font sizes: */
.wp-block-getwid-section.getwid-init {
  padding: 0 4%; }

.wp-block-getwid-section.home.intro-header.getwid-init {
  padding: 0 0; }

.section,
section,
section.wp-block-fp-section-container-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: default;
  width: 100%;
  padding: 0 4%; }
  .section .container,
  section .container,
  section.wp-block-fp-section-container-block .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 100px 0 0 0; }
    .section .container .inner-wrapper,
    section .container .inner-wrapper,
    section.wp-block-fp-section-container-block .container .inner-wrapper {
      width: 100%;
      max-width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center; }
      .section .container .inner-wrapper .content-wrapper,
      section .container .inner-wrapper .content-wrapper,
      section.wp-block-fp-section-container-block .container .inner-wrapper .content-wrapper {
        width: 100%;
        max-width: 100%; }

.gpl-column-1 {
  flex: 1 0 30%;
  margin-right: 2%; }

.wp-block-fp-section-container-block {
  overflow-x: initial; }

.wp-block-getwid-section .wp-block-getwid-section__wrapper {
  padding: 120px 0; }
  .wp-block-getwid-section .wp-block-getwid-section__wrapper .wp-block-getwid-section__inner-wrapper {
    max-width: 1280px; }
    .wp-block-getwid-section .wp-block-getwid-section__wrapper .wp-block-getwid-section__inner-wrapper .wp-block-columns {
      position: relative;
      margin-bottom: 0; }

.section-quote {
  margin-bottom: 100px;
  padding: 60px 4%; }
  .section-quote .container {
    max-width: 770px;
    margin: 0 auto;
    text-align: center; }
    .section-quote .container h2 {
      margin-bottom: 50px; }
    .section-quote .container .quoteTekst {
      position: relative; }
      .section-quote .container .quoteTekst p {
        font-size: 24px;
        font-size: 1.5rem;
        line-height: 42px;
        font-style: italic;
        text-align: left; }
      .section-quote .container .quoteTekst:before {
        font-family: "open-sans-condensed", sans-serif;
        font-size: 92px;
        font-size: 5.75rem;
        content: '“';
        position: absolute;
        top: 40px;
        left: -60px; }
      .section-quote .container .quoteTekst:after {
        font-family: "open-sans-condensed", sans-serif;
        font-size: 92px;
        font-size: 5.75rem;
        content: '”';
        position: absolute;
        bottom: -15px;
        right: -50px; }
    .section-quote .container .buttonCTA {
      padding: 15px 60px;
      border: 2px solid #E94E1B;
      border-radius: 50px;
      color: #E94E1B;
      font-family: "open-sans-condensed", sans-serif;
      font-weight: 400;
      margin-top: 20px; }
      .section-quote .container .buttonCTA:hover {
        border: 2px solid #E94E1B;
        color: #E94E1B; }

.container .block-row {
  display: flex;
  flex-flow: row wrap;
  align-items: top; }
  .container .block-row .block {
    flex: 0 0 100%;
    margin-right: 0px;
    box-sizing: border-box; }

.block-row.blocks-in-row-2 {
  flex-flow: row nowrap;
  width: 100%; }
  .block-row.blocks-in-row-2 .block {
    flex: 1 0 48%;
    margin-right: 4%;
    box-sizing: border-box; }
    .block-row.blocks-in-row-2 .block.image-block, .block-row.blocks-in-row-2 .block.block-quote {
      margin-right: 30px;
      flex: 0 1 50%; }
    .block-row.blocks-in-row-2 .block:last-child {
      margin-right: 0px; }
    .block-row.blocks-in-row-2 .block p {
      margin-bottom: 25px; }
      .block-row.blocks-in-row-2 .block p:last-child {
        margin-bottom: 0; }

.block-row.blocks-in-row-3 {
  flex-flow: row nowrap;
  width: 100%; }
  .block-row.blocks-in-row-3 .block {
    flex: 1 0 31%;
    margin-right: 3%;
    box-sizing: border-box; }
    .block-row.blocks-in-row-3 .block.image-block, .block-row.blocks-in-row-3 .block.block-quote {
      margin-right: 30px;
      flex: 0 1 50%; }
    .block-row.blocks-in-row-3 .block:last-child {
      margin-right: 0px; }
    .block-row.blocks-in-row-3 .block p {
      margin-bottom: 25px; }
      .block-row.blocks-in-row-3 .block p:last-child {
        margin-bottom: 0; }

.block-row.blocks-in-row-4 {
  flex-flow: row nowrap;
  width: 100%; }
  .block-row.blocks-in-row-4 .block {
    flex: 1 0 24%;
    margin-right: 1%;
    box-sizing: border-box; }
    .block-row.blocks-in-row-4 .block:last-child {
      margin-right: 0px; }
    .block-row.blocks-in-row-4 .block p {
      margin-bottom: 25px; }
      .block-row.blocks-in-row-4 .block p:last-child {
        margin-bottom: 0; }

.block-row.blocks-in-row-5 {
  flex-flow: row nowrap;
  width: 100%; }
  .block-row.blocks-in-row-5 .block {
    flex: 1 0 18%;
    margin-right: 1%;
    box-sizing: border-box; }
    .block-row.blocks-in-row-5 .block:last-child {
      margin-right: 0px; }
    .block-row.blocks-in-row-5 .block p {
      margin-bottom: 25px; }
      .block-row.blocks-in-row-5 .block p:last-child {
        margin-bottom: 0; }

main .wp-block-columns, .main-content .wp-block-columns {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 100px 0 0 0; }
  main .wp-block-columns .wp-block-column, .main-content .wp-block-columns .wp-block-column {
    width: 100%; }
    main .wp-block-columns .wp-block-column h2, .main-content .wp-block-columns .wp-block-column h2 {
      margin-bottom: 50px; }
    main .wp-block-columns .wp-block-column .section.block.content-block.block-tekst.wp-block-acf-block-tekst .container .inner-wrapper, .main-content .wp-block-columns .wp-block-column .section.block.content-block.block-tekst.wp-block-acf-block-tekst .container .inner-wrapper {
      flex-wrap: wrap; }
      main .wp-block-columns .wp-block-column .section.block.content-block.block-tekst.wp-block-acf-block-tekst .container .inner-wrapper .content-wrapper-title, .main-content .wp-block-columns .wp-block-column .section.block.content-block.block-tekst.wp-block-acf-block-tekst .container .inner-wrapper .content-wrapper-title {
        margin-right: 0; }
