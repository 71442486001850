@import 'base/_variables';
@import 'base/_mixins';

.wp-block-getwid-section.getwid-init {
  padding: 0 4%;
}

.wp-block-getwid-section.home.intro-header.getwid-init {
  padding: 0 0;
}

.section,
section,
section.wp-block-fp-section-container-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; //flex-start;
  cursor: default;
  width: 100%;
  padding: 0 4%;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 1280px; //1170px;
    margin: 0 auto;
    padding: 100px 0 0 0;

    .inner-wrapper {
      width: 100%;
      max-width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;

      .content-wrapper {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

// custom post overzicht blok
.gpl-column-1 {
  flex: 1 0 30%;
  margin-right: 2%;
}

.wp-block-fp-section-container-block {
  overflow-x: initial;
}

//sections opbouw
.wp-block-getwid-section {
  //margin-bottom: 100px;

  .wp-block-getwid-section__wrapper {
    padding: 120px 0;

    .wp-block-getwid-section__inner-wrapper {
      max-width: 1280px;

      .wp-block-columns {
        position: relative;
        margin-bottom: 0;
      }
    }
  }
}

.section-quote {
  margin-bottom: 100px;
  padding: 60px 4%;

  .container {
    max-width: 770px;
    margin: 0 auto;
    text-align: center;

    h2 {
      margin-bottom: 50px;
    }

    .quoteTekst {
      position: relative;

      p {
        @include font-size (24px);
        line-height: 42px;
        font-style: italic;
        text-align: left;
      }

      &:before {
        font-family: $head-stack;
        @include font-size (92px);
        content: '“';
        position: absolute;
        top: 40px;
        left: -60px;
      }

      &:after {
        font-family: $head-stack;
        @include font-size (92px);
        content: '”';
        position: absolute;
        bottom: -15px;
        right: -50px;
      }
    }

    .buttonCTA {
      padding: 15px 60px;
      border: 2px solid $oranje;
      border-radius: 50px;
      color: $oranje;
      font-family: $head-stack;
      font-weight: $regular-weight;
      margin-top: 20px;

      &:hover {
        border: 2px solid $oranje;
        color: $oranje;
      }
    }
  }
}

.container {
  .block-row {
    display: flex;
    flex-flow: row wrap;
    align-items: top;

    .block {
      flex: 0 0 100%;
      margin-right: 0px;
      box-sizing: border-box;
    }
  }
}

.block-row.blocks-in-row-2 {
  flex-flow: row nowrap;
  width: 100%;

  .block {
    flex: 1 0 48%;
    margin-right: 4%;
    box-sizing: border-box;

    &.image-block, &.block-quote {
      margin-right: 30px;
      flex: 0 1 50%;
    }

    &:last-child {
      margin-right: 0px;
    }

    p {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.block-row.blocks-in-row-3 {
  flex-flow: row nowrap;
  width: 100%;

  .block {
    flex: 1 0 31%;
    margin-right: 3%;
    box-sizing: border-box;

    &.image-block, &.block-quote {
      margin-right: 30px;
      flex: 0 1 50%;
    }

    &:last-child {
      margin-right: 0px;
    }

    p {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.block-row.blocks-in-row-4 {
  flex-flow: row nowrap;
  width: 100%;

  .block {
    flex: 1 0 24%;
    margin-right: 1%;
    box-sizing: border-box;

    &:last-child {
      margin-right: 0px;
    }

    p {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.block-row.blocks-in-row-5 {
  flex-flow: row nowrap;
  width: 100%;

  .block {
    flex: 1 0 18%;
    margin-right: 1%;
    box-sizing: border-box;

    &:last-child {
      margin-right: 0px;
    }

    p {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

main, .main-content {
  .wp-block-columns {
    // display: flex;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 100px 0 0 0;

    .wp-block-column {
      width: 100%;

      //max-width: 525px;

      h2 {
        margin-bottom: 50px;
      }

      .section.block.content-block.block-tekst.wp-block-acf-block-tekst {
        .container {
          .inner-wrapper {
            flex-wrap: wrap;

            h2.block-title {

            }

            .content-wrapper-title {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
